import request from '@/libs/request';


export function flowListApi(data) {
  return request({
    url: 'client/index',
    method: 'get',
    params:data,
  });
}


export function flowTotalApi(data) {
  return request({
    url: 'client/total',
    method: 'get',
    params:data,
  });
}


export function loginAdminGroups(data) {
  return request({
    url: 'auth/admin/groups',
    method: 'get',
    data,
  });
}


export function addFlowApi(code,data) {
  return request({
    url: 'client/add/'+code,
    method: 'post',
    data,
  });
}


export function flowInfoApi(groupId,id) {
  return request({
    url: 'client/info/'+groupId+'/'+id,
    method: 'get',
  });
}


export function flowDetailApi(groupId,id) {
  return request({
    url: 'client/detail/'+groupId+'/'+id,
    method: 'get',
  });
}

export function flowUpdateApi(groupId,id,data) {
  return request({
    url: 'client/update/'+groupId+'/'+id,
    method: 'put',
	data
  });
}