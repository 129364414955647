

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'statistics_';

export default {
  path: routePre + '/statistics',
  name: 'statistics',
  header: 'statistics',
  redirect: {
    name: `${pre}clientChannel`,
  },
  component: LayoutMain,
  children: [
	
	
	
	// {
	//   path: 'group/channel',
	//   name: `${pre}groupChannel`,
	//   meta: {
	//     auth: ['statistics-group-channel'],
	//     title: '按小组统计 - 渠道统计',
	//   },
	//   component: () => import('@/pages/statistics/group/channel/index'),
	// },
	
	// {
	//   path: 'group/member',
	//   name: `${pre}groupMember`,
	//   meta: {
	//     auth: ['statistics-group-member'],
	//     title: '按小组统计 - 成员统计',
	//   },
	//   component: () => import('@/pages/statistics/group/member/index'),
	// },
	
	{
	  path: 'client/:type?',
	  name: `${pre}client`,
	  meta: {
	    auth: ['statistics-client'],
	    title: '推量统计',
	  },
	  component: () => import('@/pages/statistics/client/index'),
	},
	
	
	
	
  ],
};
