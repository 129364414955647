

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'flow_';

export default {
  path: routePre + '/flow',
  name: 'flow',
  header: 'flow',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['flow-index'],
	    title: '我的添加',
		hiddenMenu:true,
	  },
	  component: () => import('@/pages/flow/index'),
	},
	
	{
	  path: 'add',
	  name: `${pre}add`,
	  meta: {
	    auth: ['flow-index'],
	    title: '录入量',
		activeMenu: routePre + '/flow/index',
		hiddenMenu:true,
	  },
	  component: () => import('@/pages/flow/add'),
	},
	{
	  path: 'edit',
	  name: `${pre}edit`,
	  meta: {
	    auth: ['flow-index'],
	    title: '编辑量',
		activeMenu: routePre + '/flow/index',
		hiddenMenu:true,
	  },
	  component: () => import('@/pages/flow/edit'),
	},
	
	{
	  path: 'warehouse',
	  name: `${pre}warehouse`,
	  meta: {
	    auth: ['flow-warehouse'],
	    title: '我的仓库',
	  },
	  component: () => import('@/pages/flow/warehouse/index'),
	},
	
	{
	  path: 'warehouse/add',
	  name: `${pre}warehouse/add`,
	  meta: {
	    auth: ['flow-warehouse'],
	    title: '录入仓库',
		activeMenu: routePre + '/flow/index',
		hiddenMenu:true,
	  },
	  component: () => import('@/pages/flow/warehouse/add'),
	},
	
	{
	  path: 'warehouse/edit',
	  name: `${pre}warehouse/edit`,
	  meta: {
	    auth: ['flow-warehouse'],
	    title: '修改仓库量',
		activeMenu: routePre + '/flow/index',
		hiddenMenu:true,
	  },
	  component: () => import('@/pages/flow/warehouse/edit'),
	},
  ],
};
